import * as React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  withStyles,
} from '@material-ui/core'
import { allUsersLoadingSelector, allUsersSelector, getAllUsers } from 'store/auth/configureUsers'

import AdminButton from '../AdminButton'
import AllUsersIcon from '@material-ui/icons/SupervisedUserCircle'
import Button from 'components/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import ConfigureUser from './ConfigureUser'
import { Loader } from 'components/Loader/Loader'
import SortableTableHeader from 'components/SortableTableHeader'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import moment from 'moment'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'

const styles = theme => ({
  dialogContent: {
    minWidth: 1250,
    minHeight: 600,
  },
  root: {},
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(230, 230, 230)',
      cursor: 'pointer',
    },
  },
  textField: {
    width: 300,
    marginLeft: theme.spacing(2),
  },
})

const DATE_FORMAT = 'YYYY-MM-DD'

const cols = [
  { id: 'id', label: 'ID' },
  { id: 'email', label: 'Email', style: { width: 100 } },
  { id: 'tenant', label: 'Tenant' },
  {
    id: 'isExternalUser',
    label: 'Portal User',
    getVal: row => get(row, 'isExternalUser', false) && <CheckIcon />,
  },
  {
    id: 'isSuperuser',
    label: 'Superuser',
    getVal: row => get(row, 'isSuperuser') && <CheckIcon />,
  },
  {
    id: 'status',
    sortKey: 'status',
    label: 'Status',
  },
  {
    id: 'accountActivated',
    label: 'Activation Status',
    getVal: row => {
      return get(row, 'accountActivated') === true ? 'registered' : 'pending'
    },
  },
  {
    id: 'dateJoined',
    label: 'Date Joined',
    style: { minWidth: 170 },
    getVal: row => get(row, 'dateJoined') && moment(get(row, 'dateJoined')).format(DATE_FORMAT),
  },
  {
    id: 'name',
    sortKey: 'firstName',
    label: 'Name',
    getVal: row => {
      const firstName = get(row, 'firstName') || ''
      const lastName = get(row, 'lastName') || ''
      return `${firstName} ${lastName}`
    },
  },
]

class ListAllUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      orderBy: 'tenant',
      order: 'asc',
      selectedRow: null,
      searchTerm: null,
    }
  }

  componentDidMount() {
    this.props.getAllUsers()
  }

  handleSort = (orderBy, order) => {
    this.setState({
      orderBy,
      order,
    })
  }

  toggleDialog = () => {
    const newIsOpen = !this.state.isOpen
    this.setState({ isOpen: newIsOpen })
    if (newIsOpen) this.props.getAllUsers()
  }

  sortData = data => {
    const { orderBy, order } = this.state
    let sortedData = sortBy(data, item => item[orderBy])
    if (order === 'desc') sortedData = reverse(sortedData)
    return sortedData
  }

  handleRowClick = item => () => {
    this.setState({
      selectedRow: item,
      searchTerm: null,
    })
  }

  handleUnselect = () => {
    this.setState({
      selectedRow: null,
    })
  }

  renderTitle = () => {
    const { allUsers } = this.props
    const { selectedRow } = this.state
    if (selectedRow) return get(selectedRow, 'email')
    return `${allUsers.length} Users`
  }

  debouncedInputHandler = debounce(async value => {
    this.setState({
      searchTerm: value,
    })
  }, 260)

  getStyle = row => {
    const status = get(row, 'status')
    if (status === 'inactive') return { backgroundColor: 'rgba(240, 200, 8, .5)' } // yellow
    if (status === 'deleted') return { backgroundColor: 'rgba(221, 28, 26, .5)' } // red
    return {}
  }

  renderContent = () => {
    const { classes } = this.props
    const { selectedRow, searchTerm } = this.state
    if (selectedRow) {
      return <ConfigureUser onCancel={this.handleUnselect} user={selectedRow} />
    }
    const sortedData = this.sortData(this.props.allUsers)
    const filteredData = sortedData.filter(
      item =>
        !searchTerm ||
        item.email.indexOf(searchTerm) !== -1 || // email matches
        item.tenant.indexOf(searchTerm) !== -1 // tenant matches
    )

    if (this.props.allUsersLoading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }

    return (
      <div>
        <TextField
          className={classes.textField}
          placeholder={'Search for a user or tenant...'}
          onChange={e => this.debouncedInputHandler(e.target.value)}
        />
        <Table>
          <SortableTableHeader
            stickyHeader
            onRequestSort={this.handleSort}
            cols={cols}
            defaultOrderBy={this.state.orderBy}
            defaultOrder={this.state.order}
          />
          <TableBody>
            {filteredData.map((row, idx) => {
              return (
                <TableRow
                  key={row.email}
                  onClick={this.handleRowClick(row)}
                  className={classes.tableRow}
                  style={this.getStyle(row)}
                >
                  {cols.map(col => {
                    const key = get(col, 'id')
                    const getVal = get(col, 'getVal', row => row[key])
                    const style = get(col, 'style', {})
                    return (
                      <TableCell key={`${row.email}-${key}`} style={style}>
                        {getVal(row)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const { isOpen } = this.state
    return (
      <div className={classes.root}>
        <AdminButton onClick={this.toggleDialog} text={'Manage Users'} icon={<AllUsersIcon />} />
        <Dialog
          open={isOpen}
          onClose={this.toggleDialog}
          classes={{ paper: classes.dialogContent }}
        >
          <DialogTitle id="configure-users">{this.renderTitle()}</DialogTitle>
          <DialogContent>{this.renderContent()}</DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  allUsers: allUsersSelector(state),
  allUsersLoading: allUsersLoadingSelector(state),
})

const dispatchToProps = {
  getAllUsers,
}

export default connect(mapStateToProps, dispatchToProps)(withStyles(styles)(ListAllUsers))
