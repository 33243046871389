import { useEffect } from 'react'

const SCRIPT_ID = '__SURVICATE_SCRIPT__'

interface Props {
  email: string
  firstName: string
  lastName: string
  tenant: string
  userId: string
}

const NpsSurvey = (props: Props) => {
  const { email, firstName, lastName, tenant, userId } = props

  // Sets survey user traits
  useEffect(() => {
    if (!email) {
      return
    }
    ;(function (opts) {
      opts.traits = {
        email,
        first_name: firstName,
        last_name: lastName,
        tenant,
        userId,
      }
    })(((window as any)._sva = (window as any)._sva || {}))
  }, [email, firstName, lastName, tenant, userId])

  // Loads the script
  useEffect(() => {
    ;(function (w) {
      const idExists = document.getElementById(SCRIPT_ID)
      if (!idExists) {
        const s = document.createElement('script')
        s.src = '//survey.survicate.com/workspaces/e141831d222122220a64be3b2ca7437c/web_surveys.js'
        s.id = SCRIPT_ID
        s.async = true
        const e = document.getElementsByTagName('script')[0]
        e.parentNode?.insertBefore(s, e)
      }
    })(window as any)
  }, [])

  return null
}

export default NpsSurvey
