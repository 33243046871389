import { Box, TextField, Theme, Typography, makeStyles } from '@material-ui/core'
import { MESSAGE_TYPES, showNotification } from 'store/notifications'

import Button from 'components/core/Button'
import MESSAGES from 'store/notifications/messages'
import React from 'react'
import Reaction from 'components/ProductFeedback/Reaction'
import logger from 'utils/logger'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: theme.palette.blue[50],
    border: `1px solid ${theme.palette.blue[200]}`,
    borderRadius: 0,
    left: 0,
    marginLeft: 100,
    minWidth: 1100,
    position: 'absolute',
    top: 0,
    width: 'calc(100vw - 100px)',
  },
  header: {
    color: theme.palette.blue[800],
    fontSize: 18, // one-off requested by design
    fontWeight: theme.typography.fontWeightMedium,
  },
  description: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  rule: {
    borderColor: theme.palette.blue[200],
    borderTopWidth: 0,
    margin: 0,
  },
  submitButton: {
    minWidth: 85,
  },
  formWrapper: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  input: {
    borderRadius: theme.shape.borderRadius,
    margin: `0 ${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    minWidth: 220,
    maxWidth: 500,
    width: '100%',
    '& .MuiOutlinedInput-root': {
      background: theme.palette.white,
      padding: `8px 12px 10px`,
    },
  },
}))

/**
 * See Storybook for implementation and notes
 */
type ProductFeedbackProps = {
  bannerView?: boolean
  bodyText?: React.ReactNode
  classes?: { [key: string]: string }
  componentUnderFeedback: string
  entityId: string
  feedbackPromptText: React.ReactNode
  headerText?: React.ReactNode
  placeholder?: string
  refType?: string
}

const ProductFeedback = (props: ProductFeedbackProps) => {
  const {
    bannerView = false,
    bodyText,
    componentUnderFeedback,
    entityId,
    feedbackPromptText,
    headerText,
    placeholder = 'Any additional feedback?',
    refType,
  } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [value, setValue] = React.useState<string | null>('')
  const [disabled, setDisabled] = React.useState<boolean>(true)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (Boolean(e.target.value.trim())) {
      return setDisabled(false)
    }
    return setDisabled(true)
  }

  // There is no form submit here, we just log it to MixPanel
  const handleSubmit = () => {
    logger.notify('Entity Page Feedback', {
      feedback: value,
      entityId: entityId,
      component: componentUnderFeedback,
      ...(refType && { refType }),
    })
    setValue('')
    setDisabled(true)
    dispatch(showNotification(MESSAGES.feedbackSubmitted, { type: MESSAGE_TYPES.SUCCESS }))
  }

  return (
    <Box px={3} className={classes.wrapper} data-testid="product-feedback">
      {!bannerView && (
        <>
          <Box pt={2} pb={2}>
            <Typography variant="subtitle1" className={classes.header}>
              {headerText}
            </Typography>
            <Typography variant="subtitle2" className={classes.description}>
              {bodyText}
            </Typography>
          </Box>

          <hr className={classes.rule} />
        </>
      )}

      <Box pt={1} pb={1} className={classes.formWrapper}>
        <Reaction
          componentUnderFeedback={componentUnderFeedback}
          entityId={entityId}
          feedbackPromptText={feedbackPromptText}
          refType={refType}
        />
        <TextField
          placeholder={placeholder}
          onChange={handleInputChange}
          value={value}
          multiline
          variant="outlined"
          margin="normal"
          className={classes.input}
        />
        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Box>
  )
}

export default ProductFeedback
