import { Box, Link, Theme, makeStyles } from '@material-ui/core'
import { ROLLUPS, getShipmentDetailPath } from 'utils/rollups'
import React, { useEffect, useRef } from 'react'
import { fetchTripGroups, resetTripGroups } from 'store/tripGroups/actions'
import {
  transportDetailLoadingSelector,
  transportDetailSelector,
} from 'store/transportDetail/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEmailActions, useOpenCommentsDrawerIfComments } from 'utils/hooks'

import { ApiRefTypeType } from 'store/models/definitions/ApiRefType'
import { COMMENT_DRAWER_WIDTH } from 'components/CommentsDrawer'
import CommentsDrawer from 'components/CommentsDrawer'
import EntityPageBase from 'components/EntityPageBase'
import EntityPageHeader from 'components/EntityPageHeader'
import EntityPageTabs from 'pages/EntityPage/EntityPageTabs'
import InternalUserComponent from 'components/InternalUserComponent'
import NoShipmentMsg from 'components/NoShipmentMsg'
import ProductFeedback from 'components/ProductFeedback'
import ShareComment from 'pages/ContainerPage/ShareComment'
import TimelineCardSkeleton from 'components/TimelineCardSkeleton'
import classnames from 'classnames'
import { fetchAssociatedReferences } from 'store/associatedReferences/actions'
import { fetchCommentSummaries } from 'store/comments/actions'
import { fetchCustomFields } from 'store/customFields/actions'
import { fetchTransportDetail } from 'store/transportDetail/actions'
import { getIsCommentsDrawerOpen } from 'utils/routes/comments'
import { getRefTypeLabel } from 'utils/rollups'
import { isDemo } from 'utils/browsers'
import { isExternalUserSelector } from 'store/auth/user/selectors'
import logger from 'utils/logger'
import { useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: '100%',
  },
  withProductFeedback: {
    marginTop: theme.spacing(6),
  },
  link: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  noData: {
    color: theme.palette.yellow[500],
  },
  drawerOpen: {
    width: `calc(100% - ${COMMENT_DRAWER_WIDTH}px)`,
  },
  bottomWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 100,
  },
  overviewContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  detailContainer: {
    paddingTop: theme.spacing(3),
  },
  share: {
    textAlign: 'right',
  },
  pageHeader: {
    marginBottom: 0,
  },
}))

interface MatchParams {
  externalId: string
  id?: string
  itemNumber?: string
  number?: string
  orderNumber?: string
}

interface Props {
  hideBooking?: boolean
  isPublicShare?: boolean
  refType: ApiRefTypeType
  shareModalRefType: typeof ROLLUPS[keyof typeof ROLLUPS]
}

const EntityPage = (props: Props) => {
  const { refType, isPublicShare = false, shareModalRefType } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const match = useRouteMatch<MatchParams>()
  const hasLogged = useRef<boolean>(false)
  const isExternalUser: boolean = useSelector(isExternalUserSelector)
  const transportDetail = useSelector(transportDetailSelector)
  const transportDetailLoading = useSelector(transportDetailLoadingSelector)
  const isCommentsDrawerOpen = getIsCommentsDrawerOpen(match)
  const pageHeader = transportDetail?.pageHeader
  const selfNumber = transportDetail?.self.number
  const selfType = transportDetail?.self.type
  const selfId = transportDetail?.self.id
  const { externalId } = match.params
  const isDemoEnv = isDemo()

  useOpenCommentsDrawerIfComments(externalId)

  useEffect(() => {
    if (externalId && refType) {
      dispatch(fetchTransportDetail(externalId, refType))
      dispatch(fetchCustomFields(refType, externalId))
      dispatch(fetchAssociatedReferences({ refType, externalId }))
    }

    if (!isExternalUser) {
      dispatch(fetchCommentSummaries([externalId]))
    }
  }, [refType, dispatch, externalId, isExternalUser])

  useEffect(() => {
    if (transportDetail && transportDetail.header?.trips?.length) {
      const tripIds = transportDetail.header.trips.map((trip: any) => trip.externalId)
      dispatch(fetchTripGroups(tripIds))
    }

    return () => {
      dispatch(resetTripGroups())
    }
  }, [dispatch, transportDetail])

  useEffect(() => {
    // `hasLogged` avoids logging on every render, which here was 8 times
    if (selfNumber && !hasLogged.current) {
      const msg = `${isExternalUser ? 'Portal ' : ''}Entity Page View`
      logger.notify(msg, { entityId: selfNumber, entityType: refType })
      hasLogged.current = true
    }
  }, [isExternalUser, refType, selfNumber])

  useEmailActions()

  // use the original ref type set rather than a ApiRefTypeType (the `refType` prop)
  const title = getRefTypeLabel(selfType)
  const commentIconLink =
    (selfType && externalId && getShipmentDetailPath(selfType, selfNumber, externalId)) ?? null

  if (!transportDetailLoading && !selfNumber) {
    return <NoShipmentMsg />
  } else {
    return (
      <>
        {!isDemoEnv && selfNumber && (
          <ProductFeedback
            entityId={selfNumber}
            componentUnderFeedback="Entity Page"
            refType={title}
            feedbackPromptText={
              <>
                Shipment pages are evolving with new features and enhanced navigation.{' '}
                <Link
                  className={classes.link}
                  href="#"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault()
                    window.open(
                      'https://announcekit.app/clearmetal/announcements/a-new-shipment-page-44jtqo',
                      '_blank'
                    )
                  }}
                >
                  Learn More
                </Link>
              </>
            }
            bannerView={true}
            placeholder="Tell us what you think!"
          />
        )}
        {transportDetail && (
          <EntityPageBase
            className={classnames(classes.header, {
              [classes.withProductFeedback]: !isDemoEnv,
              [classes.drawerOpen]: isCommentsDrawerOpen,
            })}
          >
            <Box px={6}>
              <Box display="flex" flexWrap="nowrap" flexDirection="row">
                <Box>
                  {externalId && (
                    <EntityPageHeader
                      classes={{ root: classes.pageHeader }}
                      externalId={externalId}
                      isContainerPage={false}
                      isLoading={transportDetailLoading}
                      isPublicShare={isPublicShare}
                      pageHeaderData={pageHeader}
                      refTypeLabel={pageHeader?.refTypeLabel}
                      commentIconLink={commentIconLink}
                    />
                  )}
                </Box>
                {selfId && (
                  <Box flexGrow="1" className={classes.share}>
                    <ShareComment
                      refIds={[selfId]}
                      shareModalRefType={shareModalRefType}
                      externalId={externalId}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {!isPublicShare &&
              (transportDetailLoading ? (
                // We are doing this here rather than in `EntityPageTabs` because it saves a lot of useless churn.
                // The tabs still handle the skeleton state if all tabs have no content.
                <TimelineCardSkeleton isAnimated={true} />
              ) : (
                <EntityPageTabs transportDetail={transportDetail} refType={refType} />
              ))}
          </EntityPageBase>
        )}
        <InternalUserComponent>
          {!isPublicShare && isCommentsDrawerOpen && externalId && (
            <CommentsDrawer entityTitle={`${title}: ${selfNumber}`} externalId={externalId} />
          )}
        </InternalUserComponent>
      </>
    )
  }
}

export default EntityPage
