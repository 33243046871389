import { Checkbox, FormControlLabel, TextField, Typography, withStyles } from '@material-ui/core'

import Button from 'components/core/Button'
import Dropdown from 'components/deprecated/Dropdown'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import { impersonateUser } from 'store/auth/impersonate/actions'
import { updateUser } from 'store/auth/configureUsers'

const styles = theme => ({
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    width: 200,
  },
  email: {
    marginRight: theme.spacing(2),
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
  saveButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.brand.color,
  },
  saveButtonLabel: {
    color: 'white',
    textTransform: 'none',
  },
  backButton: {
    backgroundColor: 'rgb(46, 62, 84)',
  },
  backButtonLabel: {
    color: 'white',
    textTransform: 'none',
  },
  disabled: {
    backgroundColor: theme.palette.grey[200],
  },
  dropdownButton: {
    minWidth: '100%',
  },
  dropdownRoot: {
    marginTop: theme.spacing(1),
  },
  impersonateButton: {
    backgroundColor: 'red',
    marginLeft: theme.spacing(6),
  },
  impersonateButtonLabel: {
    color: 'white',
    textTransform: 'none',
  },
})

const STATUS_TYPES = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
  { name: 'Deleted', value: 'deleted' },
]

const CLEARMETAL_EMAIL = 'clearmetal.com'

class ConfigureUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSuper: get(props, 'user.isSuperuser'),
      firstName: get(props, 'user.firstName', null),
      lastName: get(props, 'user.lastName', null),
      status: get(props, 'user.status', null),
      allowSave: false,
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  }

  handleSave = () => {
    const { user } = this.props
    const { firstName, lastName, isSuper, status } = this.state

    const updatedUser = {
      status,
      id: get(user, 'id'),
    }
    // only set is_superuser if it's enabled
    if (!this.isSuperDisabled()) updatedUser['isSuperuser'] = isSuper
    // only add first/last name to payload if is not null
    if (firstName) {
      updatedUser['firstName'] = firstName
    }
    if (lastName) {
      updatedUser['lastName'] = lastName
    }
    this.props.updateUser(updatedUser)
    this.handleBack()
  }

  handleBack = () => {
    this.props.onCancel()
  }

  handleIsSuperChange = (event, checked) => {
    this.setState({
      isSuper: checked,
      allowSave: true,
    })
  }

  handleFirstNameChange = e => {
    this.setState({
      firstName: e.target.value,
      allowSave: true,
    })
  }

  handleLastNameChange = e => {
    this.setState({
      lastName: e.target.value,
      allowSave: true,
    })
  }

  handleStatusChange = (item, idx) => {
    this.setState({
      status: item.value,
      allowSave: true,
    })
  }

  isSuperDisabled = () => {
    // If email is not clearmetal.com then return true
    const email = get(this.props.user, 'email')
    return email && email.indexOf(CLEARMETAL_EMAIL) === -1
  }

  getDefaultStatusIdx = user => {
    return findIndex(STATUS_TYPES, item => item.value === get(user, 'status'))
  }

  getActivateStatus = user => {
    return get(user, 'accountActivated') === true ? 'registered' : 'pending'
  }

  handleImpersonate = () => {
    const { user, impersonateUser } = this.props
    if (user.email && user.tenant) {
      if (user.status === 'active') {
        impersonateUser(user.email, user.tenant)
      } else {
        alert('You can only impersonate active users')
      }
    }
  }

  render() {
    const { classes, user } = this.props
    const { allowSave, isSuper, firstName, lastName } = this.state
    const defaultStatusIdx = this.getDefaultStatusIdx(user)
    const disabled = !allowSave
    let buttonClasses = classnames(classes.saveButton, {
      [classes.disabled]: disabled,
    })
    return (
      <div>
        <Button
          classes={{ root: classes.backButton, label: classes.backButtonLabel }}
          onClick={this.handleBack}
        >
          Back
        </Button>
        <Button
          classes={{ root: classes.impersonateButton, label: classes.impersonateButtonLabel }}
          onClick={this.handleImpersonate}
        >
          Impersonate
        </Button>

        <div className={classes.container}>
          <Typography variant="body2" className={classes.email}>
            Email: {user.email}
          </Typography>
          <br />
          <Typography variant="body2" className={classes.textField}>
            Activation Status: {this.getActivateStatus(user)}
          </Typography>
          <FormControlLabel
            disabled={this.isSuperDisabled()}
            control={
              <Checkbox
                checked={isSuper}
                onChange={this.handleIsSuperChange}
                value="is_superuser"
                color="primary"
              />
            }
            label="Is Superuser"
          />
          <TextField
            value={firstName || ''}
            placeholder="First Name"
            onChange={this.handleFirstNameChange}
          />
          <TextField
            value={lastName || ''}
            placeholder="Last Name"
            onChange={this.handleLastNameChange}
          />
          <Dropdown
            classes={{
              root: classes.dropdownRoot,
              button: classes.dropdownButton,
            }}
            items={STATUS_TYPES}
            defaultIdx={defaultStatusIdx}
            handleSelection={this.handleStatusChange}
          />
          <Button
            disabled={disabled}
            onClick={this.handleSave}
            classes={{ root: buttonClasses, label: classes.saveButtonLabel }}
          >
            Save User
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  updateUser,
  impersonateUser,
}

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfigureUser))
