import { Checkbox, FormControlLabel, Paper, Typography, withStyles } from '@material-ui/core'
import {
  adminFeaturesSelector,
  disableConsoleLogging,
  enableConsoleLogging,
  logToConsoleSelector,
  setAdminFeatures,
} from 'store/featureFlags'
import { currentTenantFromState, emailFromState } from 'store/auth/user/selectors'

import AddSource from './AddSource'
import AddTenant from './AddTenant'
import Customization from './Customization'
import InviteUsers from './InviteUsers'
import ListAllUsers from './ConfigureUsers/ListAllUsers'
import NotifyUser from './NotifyUser'
import PropTypes from 'prop-types'
import React from 'react'
import SwitchTenant from './SwitchTenant'
import TriggerAlerts from './TriggerAlerts'
import { connect } from 'react-redux'
import { subTenants } from 'store/auth/tenant/selectors'

const styles = theme => ({
  container: {
    padding: theme.spacing(10),
    height: '100%',
    marginBottom: 70,
  },
  paper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(3),
  },
  sendEmail: {
    backgroundColor: '#2e3e55',
  },
  sendEmailText: {
    color: 'white',
    textTransform: 'none',
  },
  button: {
    backgroundColor: '#2e3e55',
    marginTop: theme.spacing(2),
  },
  buttonLabel: {
    color: 'white',
    textTransform: 'none',
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  adminFeaturesForm: {
    marginLeft: theme.spacing(2),
  },
})

class AdminHomePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    setAdminFeatures: PropTypes.func.isRequired,
    enableConsoleLogging: PropTypes.func.isRequired,
    disableConsoleLogging: PropTypes.func.isRequired,
    adminFeaturesEnabled: PropTypes.bool.isRequired,
    consoleLoggingEnabled: PropTypes.bool.isRequired,
    subTenants: PropTypes.array,
  }

  handleAdminFeaturesChange = () => {
    const { adminFeaturesEnabled, setAdminFeatures } = this.props
    setAdminFeatures(!adminFeaturesEnabled)
  }

  handleConsoleLoggingChange = () => {
    const { consoleLoggingEnabled, enableConsoleLogging, disableConsoleLogging } = this.props
    if (consoleLoggingEnabled) disableConsoleLogging()
    else enableConsoleLogging()
  }

  render() {
    const {
      classes,
      email,
      currentTenant,
      adminFeaturesEnabled,
      consoleLoggingEnabled,
      subTenants,
    } = this.props

    return (
      <div className={classes.container}>
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="body2">User: {email}</Typography>
          <Typography variant="body2">Tenant: {currentTenant}</Typography>
          <Typography variant="caption">
            Subtenants: {subTenants && subTenants.length ? subTenants.join(', ') : 'None'}
          </Typography>
        </Paper>
        <div>
          <FormControlLabel
            className={classes.adminFeaturesForm}
            control={
              <Checkbox
                checked={adminFeaturesEnabled}
                onChange={this.handleAdminFeaturesChange}
                value="adminFeatures"
                color="primary"
              />
            }
            label="Admin features"
          />
        </div>
        <div>
          <FormControlLabel
            className={classes.adminFeaturesForm}
            control={
              <Checkbox
                checked={consoleLoggingEnabled}
                onChange={this.handleConsoleLoggingChange}
                value="consoleLogging"
                color="primary"
              />
            }
            label="Log events to console"
          />
        </div>
        <div className={classes.wrapper}>
          <ListAllUsers />
          <SwitchTenant />
          <TriggerAlerts />
          <AddTenant />
          <AddSource />
          <InviteUsers />
          <Customization />
          <NotifyUser />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setAdminFeatures,
  enableConsoleLogging,
  disableConsoleLogging,
}

const mapStateToProps = state => {
  return {
    email: emailFromState(state),
    currentTenant: currentTenantFromState(state),
    adminFeaturesEnabled: adminFeaturesSelector(state),
    consoleLoggingEnabled: logToConsoleSelector(state),
    subTenants: subTenants(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminHomePage))
